<template>
  <div>
    <div class="title">
      <i class="el-icon-back" @click="$router.go(-1)"></i>
    </div>
    <div class="items">
      <div
        class="item"
        v-for="(item, index) in projectList"
        :key="index"
        @click="projectClick(item)"
      >
        <img :src="item.images[0]" alt="" />
        <div class="user-name">项目：{{ item.name }}</div>
        <div class="project-name">画手：{{ item.painter_user_id }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectList: [],
    };
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      let data = {
        img_type: this.$route.query.img_type,
      };
      this.$api.mobile_project_images.list(data).then((res) => {
        this.projectList = res.data;
      });
    },
    projectClick(project) {
      this.$router.push({ name: "/receive", params: project });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: left;
  font-size: 25px;
  padding: 30px 25px;
  color: #ffffff;
}
.items {
  display: flex;
  flex-wrap: wrap;
  padding: 0 28px;
  justify-content: space-between;
  max-height: calc(100vh - 89px);
  overflow: auto;
  .item {
    width: calc(50% - 14px);
    height: 60vw;
    margin-bottom: 30px;
    text-align: left;
    img {
      width: 100%;
      height: 70%;
    }
    .user-name {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-top: 19px;
    }
    .project-name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #d6d8f5;
      margin-top: 5px;
    }
  }
}
</style>